import { useEffect, useState } from 'react';
import '../stylesheets/Projects.css'
import ProjectCard from './ProjectCard.js'
import ChemInitial from './ChemInitial.js'
import Featured from './Featured.js';

const Projects = (colorProps) => {
    const [projects, setProjects] = useState([{color: "purple"}]);
    const [featured, setFeatured] = useState({f_projects: []});
    const [idx, setIdx] = useState(0);
    const [panelInvisible, setpanelInvisible] = useState("invisible");

    useEffect(() => {
        setProjects([
            {
                title: "Automated Research",
                initials: "Ar",
                link: "",
                repo_link: "",
                description: "Enterprise-level automated research system, that given a use case described with natural language, deploys an deploys parallelized asynchronous ai agents as a research team, to find structured data addressing the research use case. Unfortunately this is a private project thus the application and code is not available.",
                experience: "",
                tech: ['LLMs', 'RAG', 'AI Webscraping', 'ScrapegraphAI', 'Perplexity', 'Selenium'],
                featured: true,
                color: "purple",
            },
            {
                title: "Optimus Prime (Transformer)",
                initials: "Tr",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/Transformer",
                description: "Full-fledged Transformer model built from scratch in PyTorch. The parts that were not made from scratch were the Embedding classes and regularization layers that I decided to just use from PyTorch. I began by training it on a task to copy user input to which it performed very well doing. ",
                experience: "I wanted to learn more about ML and how these architectures came about. I started researching and following the Attention is All You Need paper, along with tutorials, to build one from scratch in PyTorch. I then trained it on different tasks to demonstrate it's functionality including (coming soon) the ability to generate sensible text.",
                tech: ['ML', 'PyTorch', 'Python'],
                featured: false,
                color: "purple",
            },
            {
                title: "Value Based Dreaming",
                initials: "Vd",
                link: "",
                repo_link: "https://github.com/mishaalkandapath/valuedream",
                description: "Our team investigated incorporating value based gradients, and multistep prediction in the dreaming process within the DreamerV2 model. DreamerV2 was previously SOTA (replaced by DreamerV3) on long-term RL planning tasks (like Minecraft). My main contribution was the value based gradients, training and evaluating the model, and helping with the paper.",
                experience: "",
                tech: ['ML', 'PyTorch', 'Captum'],
                featured: true,
                color: "purple",
            },
            {
                title: "Diffusion Model Interpretability",
                initials: "Di",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/digit-generation",
                description: "I sought to investigate the interpretability of diffusion models for image generation. I used a simple model, and trained it to generate digits. I then used various ML interpretability techniques to investigate any patterns and \"interpretable\" observations in the model.",
                experience: "",
                tech: ['ML', 'PyTorch', 'Captum'],
                featured: true,
                color: "purple",
            },
            {
                title: "Neural Network",
                initials: "Nn",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/VanillaGradientDescent",
                description: "Arbitrary neural network built from scratch, that utilizes the inverse square cost function and stochastic gradient descent. Used it to build a model that performs with >95% accuracy on hand-digit number recognition.",
                experience: "Eager to start my journey with AI having just completed my courses in multivariable calculus, I decided to implement it all by building a neural network ground up.",
                tech: ['ML', 'Python', 'numpy'],
                featured: false,
                color: "purple",
            },
            {
                title: "Genetic Algorithm",
                initials: "Ga",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/Genetic-Algorithm",
                description: "This is an implementation of the genetic algorithm in machine learning, analogous to natural selection. It demonstrates how a population of agents can learn to reach a goal given enough evolution. Agents move to an arbitrary green goal and can navigate around obstacles efficiently after roughly 80 generations.",
                experience: "I really wanted to get started with ML in high school but lacked the math experience. This algorithm (found in a Youtube video) I understood, so I implemented it. ",
                tech: ['ML', 'Python', 'Pygame'],
                featured: false,
                color: "purple",
            },
            {
                title: "POS Tagging",
                initials: "Pt",
                link: "",
                repo_link: "",
                description: "I used Hidden Markov Models and Viterbi's Algorithm to create a model that predicted the POS tags of transcripts. On the screenplay scripts provided by my school, it performed with 93% accuracy.",
                experience: "This was a school assignment, but I loved the experience of building a statistical model, and tweaking different techniques to optimize the accuracy.",
                tech: ['NLP', 'HMM', 'numpy', 'Python'],
                featured: false,
                color: "purple",
            },
            {
                title: "MoveApp",
                initials: "Mv",
                link: "",
                repo_link: "",
                description: "Developed a new social media fitness app, coming soon to iOS and Android.",
                experience: "",
                tech: ['Flutter', 'Google Auth API', 'Dart'],
                featured: false,
                color: "red",
            },
            {
                title: "ClimateHero",
                initials: "Ch",
                link: "https://climatehero.ca",
                repo_link: "",
                description: "I worked on the ClimateHero experience for STEAMLabs. My work included implementing 3 new features, refactoring and restructuring the entire codebase for sustainability and efficiency, and solving bugs.",
                experience: "",
                tech: ['Node.js', 'React', 'axios', 'express', 'HTML', 'CSS', 'JavaScript'],
                featured: false,
                color: "red",
            },
            {
                title: "Harlowe Transpiler",
                initials: "Ht",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/twine-harlowe-compiler",
                description: "Transpiler from Harlowe 3.3.6 to Javascript that generates the according JSON 6 passage object. Designed to allow for cross-story compilation and runtime environments. Included in the project is an example integration with JS into an existing project.",
                experience: "At STEAMLabs, we wanted a way to create dynamic story nesting across various Twine stories. I set out to come up with a way to do that using the Harlowe language in Twine. I came across antlr when learning about languages and compilation, and used it to build a tool that converts Harlowe to Javascript. I then used these files and my own traversal scripts to create a demonstrated integration where Harlowe is compiled from any passage from any story, the runtime is maintained, and the stories are displayed accordingly.",
                tech: ['NextJS', 'React', 'ANTLR4', 'JavaScript'],
                featured: false,
                color: "red",
            },
            {
                title: "Bibliomathica",
                initials: "Bb",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/bibliomathica",
                description: "Online community for creating, updating, and saving math resources. Completed with admin functionality, and account management and authorization.",
                experience: "My partner and I got to take on concrete experience building with Django and using an ORM to store nested data in MongoDB. We also got to learn the process of building and deploying the web application.",
                tech: ['Django', 'MongoDB', 'Bootstrap'],
                featured: false,
                color: "red",
            },
            {
                title: "Website",
                initials: "Mm",
                link: "https://mrmackamoo.ca",
                repo_link: "https://github.com/devan-srinivasan/devan-srinivasan.github.io",
                description: "This is my personal website. I built in react to gain experience with the framework and make it easier to integrate future projects built using react.",
                experience: "",
                tech: ['React', 'UI/UX', 'Html', 'Css',],
                featured: false,
                color: "red",
            },
            {
                title: "Restify",
                initials: "Rs",
                link: "",
                repo_link: "https://github.com/MichelleChernyi/Restify",
                description: "Restify was a recreation of the popular website, AirBnb. It includes user accounts and authentication, session tokens, and host and client side views",
                experience: "This was a culminating for a third year project. The code was written very quickly to ship it on time, but it was immensely successful.",
                tech: ['Django', 'React', 'HMTL', 'CSS', 'JS'],
                featured: false,
                color: "red",
            },
            {
                title: "Spotipy",
                initials: "Sp",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/Spotipy",
                description: "Spotipy is a local Python application that uses Spotify's API to connect with the user's spotify account. It analyzes a playlist of their choice and uses the acoustic propoerties of each song to generate a new playlist they may enjoy. Account needs Spotify API account to use API.",
                experience: "This was a culminating project for first year university that gave me concrete experience in collaborative development, an introduction to using APIs, as well as change to implement abstract data structures for real-world use. We used a modified binary search tree and graph network.",
                tech: ['Python', 'Spotify API', 'Spotipy', 'Tkinter'],
                featured: false,
                color: "red",
            },
            {
                title: "MarkUs",
                initials: "Mu",
                link: "",
                repo_link: "https://github.com/MarkUsProject/Markus",
                description: "Over the summer of 2021 I worked as a software intern for the University of Toronto upgrading their marking software MarkUs. My contributions ranged from upgrading outdated components, building graph features, and even leading the team on redeveloping the graphing software. I also fixed bugs that helped thousands of TAs.",
                experience: "While the coding work itself was not major, I got incredible experience learning MVC architecture as well as project workflow in a team. This involved working with git and learning react, as well as working under a mentor.",
                tech: ['Ruby on Rails', 'React', 'chartjs-2'],
                featured: false,
                color: "red",
            },
            {
                title: "ASReview Work",
                initials: "A",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/ASReview-Work-Summer-2021",
                description: "Over the summer of 2021 I interned for a social science team at the University of Toronto as a developer. I did a lot of different work for them including data formatting and modelling, as well as testing AI software. I compressed huge datasets and wrote scripts to simulate manual work cutting time by as much as 99% in some instances. I also provided consulting into software use with regards to reviewing academit resources using AI software, namely, ASReview.",
                experience: "I got to get my hands dirty with data formatting, cleaning, and modelling before I knew what ETL pipelines were, as well as tweak and simulate ML models before I even completely understood what was going on under the hood. It was an incredible learning experience.",
                tech: ['ML', 'pandas', 'plotly', 'selenium', 'Python'],
                featured: false,
                color: "blue",
            },
            {
                title: "Web Scraping",
                initials: "Ws",
                link: "",
                repo_link: "",
                description: "A realtor had approached me and asked if I could download, format, and export an excel file that contained the names and contact info of all other realtors in toronto across all agencies. I accomplished this by learning about web scraping and data formatting and manipulation, using pandas.",
                experience: "I got experience with pandas and excel, as well as, more notably, web scraping in general.",
                tech: ['BeautifulSoup', 'pandas', 'Python'],
                featured: false,
                color: "blue",
            },
            {
                title: "Game Bots",
                initials: "Gb",
                link: "",
                repo_link: "",
                description: "I used heuristic A* search to create a bot that can solve the Huarong Dao puzzle. I used minimax search with alpha-beta pruning to solve checkers end games. Lastly, I used my own ingenuity and general search formulation to create a battleship solitaire solver using constraints satisfaction strategies.",
                experience: "These were school assignments for my intro to AI course but the learning was so rich I thought it was worth mentioning in my portfolio. ",
                tech: ['Search Algorithms', 'Heuristics', 'CSP', 'Python'],
                featured: false,
                color: "green",
            },
            {
                title: "Contest Programming",
                initials: "Cp",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/Contest-Programming-HS",
                description: "This is a highlight reel of my contest programming in high school. I was first and then second in my school for contest points on the well established coding judge, DMOJ. I also started the contest programming club and represented my school in numerous competitions.",
                experience: "I credit much of my ability to solve difficult programming challenges and do so efficiently to this work I did. I did many many more problems than the few listed in this repository and its the non-linear thinking, applied mathematics, and ingenuity that made me a great thinker and capable problem solver.",
                tech: ['Data Structures & Algorithms', 'Python'],
                featured: false,
                color: "green",
            },
            {
                title: "Volleyhead",
                initials: "Vh",
                link: "",
                repo_link: "https://github.com/devan-srinivasan/VolleyHead",
                description: "2D volleyball game inspired by the game Head Soccer. Completed with a computer player that uses 2D projectile physics and variable speed to challenge the user.",
                experience: "This project followed the completion of my junior year in high school where I played a lot of Head Soccer and finished physics, learning about projectile motion and associated equations. Hence I wanted to amalgamate these areas and implement it in code, which is what I did alongisde two friends.",
                tech: ['Unity', 'C#'],
                featured: false,
                color: "orange",
            },
            {
                title: "Mathy",
                initials: "M",
                link: "https://play.google.com/store/apps/details?id=io.sushi.Times&pli=1",
                repo_link: "https://github.com/devan-srinivasan/Mathy",
                description: "Multiplayer math strategy game, networked across local networks (LAN).",
                experience: "This was a small game that followed VolleyHead, as our chance to implement networking in a game while still building one of real value. We published it to the Play Store, although it was more of a fun project.",
                tech: ['Unity', 'Mirror', 'C#'],
                featured: false,
                color: "orange",
            },
        ]);

        setIdx(0);
    }, [])

    useEffect(() => {
        setpanelInvisible("");
        setFeatured({
            f_projects: projects.filter(proj => proj.featured),
        })
    }, [projects])

    useEffect(() => {
        colorProps.onColorChange(projects[idx].color);
    }, [idx, colorProps, projects])

    return <>
        <Featured {...featured}/>
        <section id="projects">
            <div className="projects-wrapper">
                <div className="projects-header-wrapper">
                    <div className={"projects-header " + colorProps.color}>
                        <h2 className={"projects-heading " + colorProps.color}>All Projects </h2>
                        <p className={"alt-text " + colorProps.color}>AND CONTRIBUTIONS</p>
                    </div>
                </div>
                <div className={"project-container " + panelInvisible}>
                    {(projects? <ProjectCard {...projects[idx]} /> : <></>)}
                    <div className={"proj-table " + colorProps.color}>
                        {/* <div className="click-img"><span className="clickSymb">Click them </span><img src="/images/spin_down_arrow.png" alt="curly arrow"/></div> */}
                        <div className="proj-table-legend">
                            <span style={{color: "#b581e3"}}>Machine Learning</span>
                            <span style={{color: "#f94449"}}>Full Stack</span>
                            <span style={{color: "#6ea9f7"}}>Data</span>
                            <span style={{color: "#46b358"}}>Algorithms</span>
                            <span style={{color: "orange"}}>Games</span></div>
                        <div className="proj-init-menu">
                            {projects?.map((proj, index) => {
                                let props = {
                                    title: proj.initials,
                                    idx: index,
                                    handler: setIdx,
                                    active: index === idx ? " active" : "",
                                    purple: true,
                                    color: proj.color,
                                    clickable: true,
                                };
                                return <ChemInitial key={index} {...props} />
                            })}
                            {[...Array(25 - projects?.length).keys()].map((element, index) => {
                                let props = {
                                    title: "?",
                                    idx: index,
                                    handler: () => {},
                                    active: "",
                                    purple: false,
                                    color: "grey",
                                    clickable: false,
                                };
                                return <ChemInitial key={element} {...props} />
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
}

export default Projects;