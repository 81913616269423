import { useEffect, useState } from 'react';
import '../stylesheets/Timeline.css';

const Timeline = () => {
    const [exp, setExp] = useState([])

    useEffect( () => {
        setExp([
            {
                title: "MSc in NLP",
                time: "Fall 2024 - Present",
                location: "University of Toronto",
                logo_url: "/images/uoft.png",
                details: [
                    "Pursuing a MSc in NLP under the supervision of Dr. Gerald Penn.",
                    "Current Project: Building AI technology for creative writing"
                ],
            },
            {
                title: "Applied Developer",
                time: "Summer 2024",
                location: "Enginable",
                logo_url: "/images/enginable.png",
                details: [
                    "Built large-scale AI agentic pipelines to optimize manual processes by over 99% -- reduced time from 30+ hours to mere minutes",
                    "Developed enterprise-level data engineering pipelines using customized NLP and clustering algorithms, as well as SOTA AI frameworks, optimized by cost for efficiency -- resulting in thousands of dollars and countless hours saved",
                    "Collaborated on the design of a SOTA RAG system for business processes",
                    "Served as a technical consultant, leading client conversations and demos"
                    ],
            },
            {
                title: "Undergraduate Research Intern",
                time: "Spring 2024",
                location: "embARC",
                logo_url: "/images/uoft.png",
                details: [
                    "I was a research intern under the supervision of Dr. Nandita Vijaykumar in her research group, embARC.",
                    "I used GPU profilers (Nsight Systems) to analyze the memory transfer during RL training inside Nvidia's Isaac Gym",
                    "I also learned about task planning and SLAM systems for automated chemistry labs"
                        ],
            },
            {
                title: "Operations and Development",
                time: "Fall 2023",
                location: "Koala Studios",
                logo_url: "/images/koala_small.png",
                details: [
                            "Designed and built a blog page for the company’s website in React, resulting in better brand establishment and educational community centred around the company’s expertise",
                            "Developed and manage our marketing strategy and sales strategy; this includes writing & revising client project proposals, conducting market research and developing reports, as well as researching leads and creating pitches",
                            "Signed multiple clients within the first 2 months of working",
                        ],
            },
            {
                title: "Junior Frontend Developer",
                time: "Summer 2023",
                location: "Steamlabs",
                logo_url: "/images/steamlabs.png",
                details: [
                            "Designed and built a new navigation & tracking system feature for the main project used by hundreds",
                            "Built an animated component in React and developed routes and context structures in the Node server, improving the game experience in response to user feedback & testing",
                            "Ideated and built a transpiler using antlr and js-interpreter to parse Harlowe code in Twine"
                        ],
            },
            {
                title: "Founder",
                time: "2022 - 2023",
                location: "Mathamoo",
                logo_url: "/images/mathamoologo.png",
                details: [
                            "Having been a tutor and math educator for a long time, I created my own business to teach math and support those in need of extra help.",
                            "I trained tutors on my educational strategies and approaches to delivering mathematics.",
                            "All students they tutored reported notable academic improvement.",
                            "New transpiler introduced cross-story dynamic functionality into Twine-based games previously unavailable"
                        ],
            },
            {
                title: "Solutions Associate",
                time: "Summer 2022",
                location: "Deloitte",
                logo_url: "/images/deloitte.png",
                details: [
                    "Built frontend design from figma mock-up for AI / big data internal project using React",
                    "Founded the CDC Learning Community of over 90 members across Deloitte Montreal",
                    "Trained and certified myself in Azure and Databricks ahead of schedule by 5 days and taught others as well",
                    "Synthesized then created documentation for solutions using Azure Data Factory and Azure Synapse Pipelines",
                ]
            },
            {
                title: "Research and Software Intern",
                time: "Summer 2021",
                location: "University of Toronto - Social Science Research Team",
                logo_url: "/images/uoft.png",
                details: [
                    "Interned for a social science research team to use programming to help them understand tools and produce data",
                    "Used AI software API to review massive datasets, cutting time from 60+ hours to mere minutes",
                    "Saved the team 3+ hours by mass-producing datasets through script automation and creating custom plots for analysis",
                    "Utilized phonetic analysis libraries to assess AI transcription software computationally, optimizing the duration of the task from 50 minutes to under 10 seconds",
                ]
            },
            {
                title: "Software Intern",
                time: "Summer 2021",
                location: "University of Toronto - CS Dept.",
                logo_url: "/images/uoft.png",
                details: [
                    "Interned for the SDS team at UofT to maintain and upgrade the school’s marking software",
                    "Improved quality of the code by utilizing react-chartjs2 library and reducing code needed (Ruby & React)", 
                    "Solved bugs such as missing columns when downloading CSV grades reports improving user experience for TAs. My changes are currently utilized by thousands of students, and hundreds of TAs",
                ]
            },
            {
                title: "Bachelor's Degree",
                time: "2020 - 2024",
                location: "University of Toronto",
                logo_url: "/images/uoft.png",
                details: [
                            "HBSc Computer Science Specialist & Mathematics Major",
                            "4.0 GPA"
                        ],
            },
        ])
    }, [])
    return <>
        <section id='timeline'>
            <div className="timeline-wrapper">
                <h2 className='timeline-header'>Timeline </h2>
                <div className="timeline-content">
                    <div className='progressbar'>
                        <div className='progressbar-white'></div>
                    </div>
                    <div className='experience-wrapper'>
                        {exp?.map((item, idx) => {
                            return (
                                <div key={idx} className='experience'>
                                        <div className="experience-left">
                                            <p className="experience-time">{item.time}</p>
                                            <div className='bubble'></div>
                                        </div>
                                        <div className='experience-right'>
                                            <img src={item.logo_url} alt="logo"></img>
                                            <div className='experience-right-right'>
                                                <h2>{item.title}</h2>
                                                <p className="experience-loc">{item.location}</p>
                                                <ul>
                                                    {item.details.map((detail, index) => <li key={index}>{detail}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            )
                        })}
                    </div>  
                </div>
            </div>
        </section>
    </>;
}

export default Timeline;